import React from 'react';
import Layout from '../components/layout';
import { Link, graphql, useStaticQuery } from 'gatsby';

import tableStyles from './cards.module.scss'

export const query = graphql`
    query {
        allNcaabCsv {
            group(field: date) {
              fieldValue
              nodes {
                awayTeam
                away_points
                date
                away_wins
                homeTeam
                home_points
                home_wins
                point_total
                spread
              }
            }
          }
    }
`

const Ncaab = (props) => {

    console.log(props.data.allNcaabCsv.group)

    return (
        <Layout>
            <h1 style = {{textAlign: 'center'}}>NCAA Men's Basketball Predictions</h1>
            <p style = {{textAlign: 'center'}}>
                Updated regularly to account for recent games and roster updates.
            </p>
            <div>
                {props.data.allNcaabCsv.group.map((dt) => {
                    return(
                        <div className={tableStyles.gameCard}>
                            <p><b>{dt.fieldValue}</b></p>
                            {dt.nodes.map((game) => {
                                return(
                                    <table className={tableStyles.table}>
                                        <thead>
                                            <tr>
                                                <th>team</th>
                                                <th>score</th>
                                                <th>prob</th>
                                                <th>spread</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{game.awayTeam}</td>
                                                <td>{game.away_points}</td>
                                                <td>{game.away_wins}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>{game.homeTeam}</td>
                                                <td>{game.home_points}</td>
                                                <td>{game.home_wins}</td>
                                                <td>{game.spread}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )
                            })}
                                                        <hr></hr>

                        </div>
                    )
                })}
            </div>
        </Layout>
    )
}

export default Ncaab